.noquotes {
  height: 20rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noquotes p {
  color: #262c2c;
  font-size: 3rem;
  font-weight: bold;
}

